import React from 'react'
import { Titre } from './Title.element'


const Title = () => {
  return (
    <Titre>
        INSCRIPTION DIFFUSEUR
    </Titre>
  )
}

export default Title
