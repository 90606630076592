import React from 'react'
import ContenuFirst from './ContenuFirst/ContenuFirst.js'
import ContenuSeconde from './ContenuSeconde/ContenuSeconde.js'
import ContenuThird from './ContenuThird/ContenuThird.js'

import Title from './Title/Title.js'
// import { Formulair, Title } from './Formular.element.js'


const Formular = () => {
  return (
   <>
   <Title/>
   <ContenuFirst/>
   <ContenuSeconde/>
   <ContenuThird/>
   
   </>
  )
}

export default Formular
